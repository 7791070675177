<template>
  <div>
    <div class="px-0 py-6 bg-white rounded-2xl shadow-lg mx-4">
      <div class="flex justify-start">
        <b class="text-left text-2xl mx-2 text-gray-600 pb-6 px-8"
          >Información del usuario registrado</b
        >
      </div>
      <hr />
      <form
        @submit.prevent="fetchCustomer"
        class="
          max-w-4xl
          grid grid-cols-1
          md:grid-cols-2
          lg:grid-cols-3
          justify-end
          items-end
          gap-5
          px-8
        "
      >
        <BaseInput
          v-if="disabled"
          ref="identificationNumber2"
          type="text"
          label="Identificación"
          placeholder="Ej: 225577885"
          v-model="name_identification"
          :required="true"
          :disabled="disabled"
        />
        <BaseSelect
          v-if="!disabled"
          ref="identificationType"
          label="Tipo de identificación"
          :selectOptions="formOptions.optionsIdentificationType"
          v-model="form.identification_type"
          :required="true"
          :disabled="disabled"
        />
        <BaseInput
          ref="identificationNumber"
          type="text"
          label="Número de identificación"
          placeholder="Ej: 225577885"
          v-model="form.identification_number"
          :required="true"
          :disabled="disabled"
          :identification="form.identification_type"
        />
        <a-button
          v-if="!disabled"
          type="primary"
          html-type="submit"
          class="mt-5 md:mt-px"
          :block="true"
          shape="round"
          :disabled="!(form.identification_type && form.identification_number)"
          :loading="loading"
        >
          CONSULTAR USUARIO
        </a-button>
        <a-button
          v-if="disabled"
          type="primary"
          @click.native="cleanForm"
          class="mt-5 md:mt-px"
          :block="true"
          shape="round"
        >
          LIMPIAR CONSULTA
        </a-button>
      </form>
    </div>
    <div v-if="customer" class="p-6 bg-white rounded-2xl shadow-lg mt-4 mx-4">
      <h3 class="text-left text-2xl text-blue-dark py-2">
        <b>Datos de contacto</b>
      </h3>
      <div class="grid grid-cols-2 gap-4 w-full">
        <BaseRadio
          ref="personType"
          name="personType"
          id="personType"
          label="Tipo de persona"
          :radioOptions="formOptions.optionsPersonType"
          v-model="customer.person_type"
          :disabled="true"
        />
      </div>
      <CustomerNatural v-if="customer.person_type == 1" :customer="customer" />
      <CustomerLegal v-if="customer.person_type == 2" :customer="customer" />
      <div class="mx-auto w-1/2">
        <BaseButton
          @click.native="complaintRegistration()"
          class="my-6 px-6 w-full"
          label="RADICAR NUEVA SOLICITUD"
        />
      </div>
    </div>
    <div class="py-12" v-if="legend">
      <b class="text-center text-4xl mx-auto text-gray-400">{{ legend }}</b>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CustomerNatural from "@/components/Dashboard/Customers/CustomerNatural";
import CustomerLegal from "@/components/Dashboard/Customers/CustomerLegal";

export default {
  components: {
    CustomerLegal,
    CustomerNatural,
  },
  data() {
    return {
      form: {
        identification_type: "",
        identification_number: "",
      },
      customer: null,
      legend: "",
      loading: false,
      disabled: false,
      name_identification: "",
    };
  },
  methods: {
    complaintRegistration: function () {
      const { identification_type, identification_number } = this.form;

      this.$router.push({
        name: "ComplaintRegistration",
        query: { identification_number, identification_type },
      });
    },
    fetchCustomer: async function () {
      const { identification_type, identification_number } = this.form;
      this.loading = true;
      this.customer = null;
      this.showToast("success", "Buscando datos del usuario.");
      let { error, data } = await this.$api.getQueryPerson(
        `?identification_number=${identification_number}&identification_type=${identification_type}`
      );
      this.loading = false;

      if (error) {
        this.showToast(
          "error",
          "No se encontró un usuario para esa identificación."
        );
        this.legend = "No se encontró un usuario para esa identificación.";
        this.cleanForm();
        return;
      }

      if (data) {
        this.disabled = true;
        // Modification: 2022-09-13 Only CF
        data.results = data.results.filter(item => item.position === null)
        if (data.results.length === 0) {
          this.showToast(
            "error",
            "No se encontró un usuario para esa identificación."
          );
          this.legend = "No se encontró un usuario para esa identificación.";
          return;
        }
        const user = data.results[0];
        const identification_type_name =
          this.$store.state.form.optionsIdentificationType.find(
            (item) => item.id == user.identification_type
          )?.name;
        this.name_identification = identification_type_name;

        this.customer = {
          identification_type: user.identification_type,
          identification_number: user.identification_number,
          birth_date: user.birth_date,
          person_type: user.person_type,
          first_name: user.user_id.first_name,
          last_name: user.user_id.last_name,
          phone: user.user_id.phone,
          email: user.user_id.email,
          identification_type_name,
        };
        this.legend = "";
      }
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    cleanForm() {
      this.form = {
        identification_type: "",
        identification_number: "",
      };
      this.disabled = false;
    },
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
};
</script>